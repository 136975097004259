<script setup lang="ts">
import { ref, computed, watch, reactive } from 'vue'
import { PaymentMethodCreditCard } from '/~/types/api'
import { floorFigure, roundFigure } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { getExpiryDate } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import PaymentsMakeCcOption from './cc-option.vue'
import PaymentsMakeDbOption, { type BankAccount } from './db-option.vue'
import PaymentsMakeSection from '../section.pure.vue'

export interface PayByPointsProps {
  paymentMethods: Array<PaymentMethodCreditCard | BankAccount>
  remainingTotal?: number
  flowType?: FlowType
  loading: boolean
  points: number
  onAddNewPaymentMethod?: () => void
  burnPointsRates: {
    payment: number
    statement: number
  }
  taxation: {
    label: string
  }
  processingFeeOptions: {
    loading: boolean
    value: number
  }
}

type PointsPayload = {
  type: PaymentMethodType.points
  calculatedAmount: number
  pointsToUse: number
}

type SplitUpdateEventPayloads = PointsPayload & {
  subtype: PaymentMethodType.creditCard | PaymentMethodType.bankAccount
  id: string
}

type PointsOnlyUpdateEventPayloads = PointsPayload

type NotEnoughUpdateEventPayloads = null

export type UpdateEventPayloads =
  | SplitUpdateEventPayloads
  | PointsOnlyUpdateEventPayloads
  | NotEnoughUpdateEventPayloads

const props = withDefaults(defineProps<PayByPointsProps>(), {
  points: 0,
  paymentMethods: () => [],
  remainingTotal: 0,
  flowType: FlowType.payment,
})

const emit = defineEmits<{
  (event: 'update', payloads: UpdateEventPayloads): void
}>()

const form = reactive<{
  pointsToUseInput: number | undefined
  pointsToUseSlider: number
}>({
  pointsToUseInput: 0,
  pointsToUseSlider: 0,
})

const method = ref<string>()

const burnPointsRate = computed(() => {
  switch (props.flowType) {
    case FlowType.statement:
      return props.burnPointsRates.statement

    default:
      return props.burnPointsRates.payment
  }
})

const pointsMaxToUse = computed(() => {
  const maxPoints = Math.ceil(props.remainingTotal / burnPointsRate.value)

  return Math.min(maxPoints, props.points)
})

const calculatedAmount = computed(() => {
  const amount = floorFigure(
    (form.pointsToUseInput || 0) * burnPointsRate.value
  )

  return Math.min(props.remainingTotal, amount)
})

const amountToPay = computed(() =>
  roundFigure(props.remainingTotal - calculatedAmount.value)
)

const isSubMethodDisabled = computed(
  () => calculatedAmount.value === 0 || amountToPay.value === 0
)

const paymentMethodsListPrep = computed(() => {
  return props.paymentMethods.map((item: any) => {
    item.value = item.id

    if (item.type === 'cc') {
      const cardNumber = `**** ${item.number.slice(-4)}`
      const expiryDate = getExpiryDate(item)
      const isNotAllowed = item.state.toLowerCase() === 'restricted'

      item.text = `${item.brand} ${cardNumber}`
      item.hiddenNumber = `${item.number.slice(-4)}`
      item.disabled = expiryDate.isExpired || isNotAllowed
    } else if (item.type === 'db') {
      item.text = `${item.accountName} ${item.accountNumber}`
    }

    return item
  })
})

const paymentMethodId = ref()
const subtype = computed(() => {
  const _method = paymentMethodsListPrep.value?.find(
    (item) => item.id === paymentMethodId.value
  )

  return _method?.type
})

watch(amountToPay, (amount) => {
  if (method.value && amount === 0) {
    method.value = undefined
    paymentMethodId.value = undefined
  }

  if (!method.value || amount === 0) {
    emit('update', {
      type: PaymentMethodType.points,
      calculatedAmount: calculatedAmount.value,
      pointsToUse: form.pointsToUseInput ?? 0,
    })
  } else {
    if (calculatedAmount.value) {
      emit('update', {
        type: PaymentMethodType.points,
        subtype: subtype.value,
        id: paymentMethodId.value,
        calculatedAmount: calculatedAmount.value,
        pointsToUse: form.pointsToUseInput ?? 0,
      })
    } else {
      method.value = undefined
      paymentMethodId.value = undefined
      emit('update', {
        type: PaymentMethodType.points,
        calculatedAmount: calculatedAmount.value,
        pointsToUse: form.pointsToUseInput as number,
      })
    }
  }
})

watch(method, (id) => {
  if (id) {
    paymentMethodId.value = id

    if (subtype.value) {
      emit('update', {
        type: PaymentMethodType.points,
        subtype: subtype.value,
        id: paymentMethodId.value,
        calculatedAmount: calculatedAmount.value,
        pointsToUse: form.pointsToUseInput ?? 0,
      })
    }
  } else {
    if (amountToPay.value === 0) return

    paymentMethodId.value = undefined

    emit('update', {
      type: PaymentMethodType.points,
      calculatedAmount: calculatedAmount.value,
      pointsToUse: form.pointsToUseInput ?? 0,
    })
  }
})

function onRangeInput(value: number) {
  form.pointsToUseSlider = value
  form.pointsToUseInput = value
}

function onPointsInput(value: number) {
  form.pointsToUseInput = value
  if (value <= pointsMaxToUse.value) {
    form.pointsToUseSlider = value
  } else {
    form.pointsToUseSlider = pointsMaxToUse.value
  }
}

watch(
  () => props.paymentMethods,
  () => {
    method.value = undefined
  }
)
</script>

<template>
  <payments-make-section label="Pay via redeeming points" :loading="loading">
    <div class="pl-1 text-lg font-bold md:text-xl">
      <div class="-mt-1 flex gap-2">
        <span>1.</span>
        Amount of points to redeem
      </div>
    </div>

    <div class="border px-4 py-4">
      <div class="px-1 pt-1">{{ formatPoints(points) }} PTS available</div>

      <base-range
        :value="form.pointsToUseSlider"
        class="w-full pt-[17px]"
        :min="0"
        :dot-size="24"
        :height="8"
        placement="top"
        formatter="{value}"
        :max="pointsMaxToUse"
        :disabled="points < 1"
        @input="onRangeInput"
      />
    </div>

    <div class="flex gap-4">
      <div class="w-1/2">
        <base-input
          :key="'points_redeemed'"
          :ref="'points_redeemed'"
          :value="form.pointsToUseInput"
          :disabled="loading"
          :mask="{ mask: Number, scale: 0 }"
          :label="'Points redeemed'"
          :entry-class="'rounded-sm h-12'"
          :label-class="'!text-lg font-bold !-top-3 !-left-1'"
          :validation="{
            rules: `points_balance:${points}|points_max_value:${pointsMaxToUse}`,
            mode: 'aggressive',
          }"
          @input="onPointsInput"
        />
      </div>
      <div class="w-1/2">
        <base-input
          :key="'remaining'"
          :ref="'remaining'"
          :disabled="loading"
          :value="amountToPay"
          :label="'Remaining to pay'"
          currency
          :mask="{
            prefix: '$',
            numeral: true,
            numeralPositiveOnly: true,
            noImmediatePrefix: true,
            rawValueTrimPrefix: true,
            numeralIntegerScale: 12,
            numeralDecimalScale: 2,
            min: 0,
          }"
          :entry-class="'rounded-sm h-12 pointer-events-none'"
          :label-class="'!text-lg font-bold !-top-3 !-left-1'"
        />
      </div>
    </div>

    <div
      class="!mt-0 flex items-center justify-between pl-1 text-lg font-bold md:text-xl"
    >
      <div
        class="-mt-1 flex gap-2"
        :class="{
          'text-fg-disabled': isSubMethodDisabled,
        }"
      >
        <span>2.</span>
        Select payment method for remaining amount
      </div>

      <base-button
        v-if="onAddNewPaymentMethod"
        size="sm"
        :disabled="isSubMethodDisabled"
        @click="onAddNewPaymentMethod"
      >
        + Add new
      </base-button>
    </div>

    <base-select
      v-model="method"
      :disabled="loading || isSubMethodDisabled"
      :options="paymentMethodsListPrep"
      default-option="Select payment method"
      :validation="{
        name: 'Payment Method',
      }"
      class="!pt-0"
      entry-class="rounded-sm h-12 text-eonx-neutral-800 !pl-3"
      select-list-class="divide-y !box-border !py-0 !rounded-sm !max-h-36 sm:!max-h-48"
      custom-select
      fullwidth-popup
      no-label
      :select-first="false"
      :popup-offset="{ top: 8 }"
      placeholder="Select payment method"
      :search="{
        enabled: true,
        allowSpace: true,
        fields: ['hiddenNumber', 'accountNumber'],
        maxlength: 32,
      }"
      empty-text="no matching Payment Method"
    >
      <template #selected="{ option }">
        <payments-make-db-option
          v-if="option.type === 'db'"
          :method="option"
          no-padding
          is-option-selected
        />
        <payments-make-cc-option
          v-else-if="option.type === 'cc'"
          :method="option"
          :processing-fee-options="processingFeeOptions"
          no-padding
          is-option-selected
          :taxation="taxation"
        />
      </template>

      <template #item="{ option, selected }">
        <payments-make-db-option
          v-if="option.type === 'db'"
          :method="option"
          :is-selected="selected"
        />
        <payments-make-cc-option
          v-else-if="option.type === 'cc'"
          :method="option"
          :is-selected="selected"
          :taxation="taxation"
        />
      </template>
    </base-select>
  </payments-make-section>
</template>
